// Fonts
$font-family-body: "JetBrains Mono", monospace;
$font-family-mono: "JetBrains Mono", monospace;
$font-family-tt: "Inconsolata", monospace;
$font-size: 18px;
$code-font-size: 16px;
$line-height: 2.725;
$page-width: 57rem;
// Logo
$logo-width: 50px;
$logo-height: 50px;
$logo-grayout: true;
// Colors
$colors: "light" // white dark light classic
